import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../graphql/fragments"

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../components/cover-main/cover-main"
import { Section, Container } from "../../components/grid/grid"
import { HeadingBasic } from "../../components/heading/heading"
import { ColTextWImage } from "../../components/acf-col-image-w-text"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const Partner = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerCommercial.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        pageBy(uri: "partners") {
          title
          blocks {
            name
            ...CoreParagraphBlock
            ...CoreCoverBlock
            ... on WordPress_AcfColImageWTextBlock {
              acf {
                label
                title
                text
                image {
                  sourceUrl
                }
                logo {
                  sourceUrl
                }
                buttons {
                  text
                  link {
                    url
                    target
                  }
                }
              }
            }
          }
          translation(language: ID) {
            title
            blocks {
              name
              ...CoreParagraphBlock
              ...CoreCoverBlock
              ... on WordPress_AcfColImageWTextBlock {
                acf {
                  label
                  title
                  text
                  image {
                    sourceUrl
                  }
                  logo {
                    sourceUrl
                  }
                  buttons {
                    text
                    link {
                      url
                      target
                    }
                  }
                }
              }
            }
            seo {
              title
              metaDesc
              twitterTitle
              twitterDescription
              opengraphTitle
              opengraphDescription
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
      }
    }
  `)

  const wordPress =
    data?.wordPress?.pageBy?.translation?.blocks ||
    data?.wordPress?.pageBy?.blocks
  const seo =
    data?.wordPress?.pageBy?.translation?.seo || data?.wordPress?.pageBy?.seo
  const cover =
    wordPress &&
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]
  let paragraphs = []

  if (wordPress?.filter(x => x.name === "core/paragraph")?.length > 0) {
    wordPress
      .filter(x => x.name === "core/paragraph")
      .map(item =>
        paragraphs.push(item.attributes.content.split("<br>").join(" "))
      )
  }

  return (
    <Layout location={location} currentLocation={location.pathname} lang="ID">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data?.imgVideoCover?.childImageSharp?.fluid
        }
        twitterImg={
          cover
            ? cover?.attributes?.url
            : data?.imgVideoCover?.childImageSharp?.fluid
        }
        img={
          cover
            ? cover?.attributes?.url
            : data?.imgVideoCover?.childImageSharp?.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {cover && (
        <CoverMain
          h1={
            data?.wordPress?.pageBy?.translation?.title ||
            data?.wordPress?.pageBy?.title
          }
          img={
            cover
              ? cover.attributes.url
              : data.imgCoverMain.childImageSharp.fluid
          }
          className="cover-md cover-detail"
        >
          <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
        </CoverMain>
      )}

      <Section className="bg-light">
        <Container className="mw-lg">
          <HeadingBasic text={paragraphs} className="mb-0" />
        </Container>
      </Section>

      <Section>
        <Container className="mw-lg">
          {wordPress?.map((item, i) => {
            switch (item.name) {
              case "acf/col-image-w-text":
                return (
                  <ColTextWImage key={`${item.acf.title}-${i}`} data={item} />
                )
              default:
                return <div key={`default-${i}`}></div>
            }
          })}
        </Container>
      </Section>
    </Layout>
  )
}

export default Partner
